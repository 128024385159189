import { TranslationMessages } from 'react-admin';

import finnishMessages from 'ra-language-finnish';

const software = {
    name: 'Ohjelmisto |||| Ohjelmistot',
    fields: {
        name: 'Nimi',
        configs: 'Asetukset',
        hostname: 'Isäntänimi',
        variables: 'Muuttujat',
        key: 'Avain',
        value: 'Arvo',
    },
    validation: {
        hostname: 'Annathan vain isäntänimen ilman protokollaa, porttia tai pathia',
        noDuplicateHostnames: 'Varmistathan, että jokainen isäntänimi on uniikki',
    },
};

const customFinnishMessages: TranslationMessages = {
    ...finnishMessages,
    resources: {
        software,
    },
};

export default customFinnishMessages;
