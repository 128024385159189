import React, { FC } from 'react';
import { Admin, Resource } from 'react-admin';
import { CssBaseline } from '@material-ui/core';

import AdminConfig from './Config';
import Software from './Resources/Software';

declare global {
    interface Window {
        config: {
            issuer: string;
            clientId: string;
            apiUrl: string;
        };
    }
}

const App: FC = () => (
    <>
        <CssBaseline />
        <Admin {...AdminConfig}>
            <Resource name='software' {...Software} />
        </Admin>
    </>
);

export default App;
