import { fetchUtils } from 'react-admin';
import dataProvider from './dataProvider';
import { getUser } from '../AuthProvider';

const httpClient = async (url: string, options: fetchUtils.Options = {}) => {
    const headers = new Headers(options.headers || { Accept: 'application/json' });
    const user = await getUser();
    if (user?.access_token) headers.set('Authorization', `${user.token_type} ${user.access_token}`);
    options.headers = headers;
    return fetchUtils.fetchJson(url, options);
};

export default {
    ...dataProvider(window.config.apiUrl || '', httpClient),
};
