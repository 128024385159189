import polyglotI18nProvider from 'ra-i18n-polyglot';

import finnishMessages from '../i18n/fi';

const I18nProvider = polyglotI18nProvider((locale) => {
    switch (locale) {
        case 'fi':
        default:
            return finnishMessages;
    }
}, 'fi');

export default I18nProvider;
