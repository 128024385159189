import React, { FC, useEffect, useState } from 'react';
import { useLogin } from 'react-admin';

import { signInRedirect } from './AuthProvider';

const LoginPage: FC = () => {
    const login = useLogin();
    const [error, setError] = useState();

    useEffect(() => {
        const { code, state } = getCodeAndState();

        if (!code || !state)
            (async function test() {
                await signInRedirect();
                localStorage.setItem('login', 'true');
            })();
    }, []);

    useEffect(() => {
        const { code, state } = getCodeAndState();

        if (code && state) {
            login({})
                .catch((reason) => setError(reason))
                .finally(() => localStorage.removeItem('login'));
        }
    }, [login]);

    const getCodeAndState = () => {
        const { searchParams } = new URL(window.location.href);
        return {
            code: searchParams.get('code'),
            state: searchParams.get('state'),
        };
    };

    return error ? <span>{error}</span> : null;
};

export default LoginPage;
