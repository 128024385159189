import { AdminProps } from 'react-admin';

import authProvider from './AuthProvider';
import dataProvider from './DataProvider';
import i18nProvider from './I18nProvider';
import loginPage from './LoginPage';

const config: AdminProps = {
    authProvider,
    dataProvider,
    i18nProvider,
    loginPage,
};

export default config;
