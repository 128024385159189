import React, { FC } from 'react';
import {
    ArrayField,
    ArrayInput,
    Create as RaCreate,
    Datagrid,
    CreateProps,
    Edit as RaEdit,
    EditProps,
    List as RaList,
    ListProps,
    Show as RaShow,
    ShowProps,
    SimpleForm,
    SimpleFormIterator,
    SimpleFormProps,
    SimpleShowLayout,
    TextField,
    TextInput,
    maxLength,
    regex,
    required,
} from 'react-admin';
import { Validator } from 'ra-core';
import { makeStyles } from '@material-ui/core';

import icon from '@material-ui/icons/Category';

import ConfigLink from '../Component/ConfigLink';

import { Software, SoftwareConfig, SoftwareConfigVariable, Validation } from '../types';

const useStyles = makeStyles({
    hideThead: {
        '& > thead': {
            display: 'none',
        },
    },
});

const noDuplicateHostnames: Validator = (value, values) => {
    if (
        new Set((values as Software)?.configs?.map((config) => config?.hostname || null)).size !== values.configs.length
    )
        return 'resources.software.validation.noDuplicateHostnames';
};

const validation: Validation<Software> = {
    name: [required(), maxLength(64)],
    configs: [],
};

const configValidation: Validation<SoftwareConfig> = {
    hostname: [
        maxLength(255),
        regex(
            /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/g,
            'resources.software.validation.hostname',
        ),
        noDuplicateHostnames,
    ],
    variables: [],
};

const configVariableValidation: Validation<SoftwareConfigVariable> = {
    key: [required(), maxLength(64)],
    value: [required(), maxLength(255)],
};

const List: FC<ListProps> = (props) => (
    <RaList {...props}>
        <Datagrid rowClick='show'>
            <TextField source='name' />
            <ConfigLink type='js' />
        </Datagrid>
    </RaList>
);

const Show: FC<ShowProps> = (props) => {
    const classes = useStyles();

    return (
        <RaShow {...props}>
            <SimpleShowLayout>
                <TextField source='name' />
                <ArrayField source='configs'>
                    <Datagrid>
                        <TextField source='hostname' />
                        <ArrayField source='variables' className={classes.hideThead}>
                            <Datagrid>
                                <TextField source='key' />
                                <TextField source='value' />
                            </Datagrid>
                        </ArrayField>
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </RaShow>
    );
};

const Form = (props: Partial<SimpleFormProps>) => (
    <SimpleForm {...props}>
        <TextInput source='name' validate={validation.name} />
        <ArrayInput source='configs'>
            <SimpleFormIterator>
                <TextInput source='hostname' validate={configValidation.hostname} />
                <ArrayInput source='variables'>
                    <SimpleFormIterator>
                        <TextInput source='key' validate={configVariableValidation.key} />
                        <TextInput source='value' validate={configVariableValidation.value} />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleFormIterator>
        </ArrayInput>
    </SimpleForm>
);

const Create: FC<CreateProps> = (props) => (
    <RaCreate {...props}>
        <Form redirect='show' />
    </RaCreate>
);

const Edit: FC<EditProps> = (props) => (
    <RaEdit {...props}>
        <Form />
    </RaEdit>
);

export default {
    list: List,
    show: Show,
    create: Create,
    edit: Edit,
    icon,
};
