import React, { FC, MouseEvent } from 'react';
import { FieldProps } from 'react-admin';

const Field: FC<Props> = ({ record: { id } = {}, type }) => {
    const link = `${window.config.apiUrl}/config/${id}.${type}`;

    const returnFalse = (event: MouseEvent) => {
        event.stopPropagation();
        return false;
    };

    return (
        <a href={link} target='_blank' rel='noopener noreferrer' onClick={returnFalse}>
            {link}
        </a>
    );
};

interface Props extends FieldProps {
    type: 'js';
}

export default Field;
