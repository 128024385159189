import { AuthProvider as RaAuthProvider } from 'react-admin';
import { User, UserManager } from 'oidc-client';

const userManager = new UserManager({
    authority: window.config.issuer,
    client_id: window.config.clientId,
    redirect_uri: window.location.origin,
    response_type: 'code',
    scope: 'openid',
    automaticSilentRenew: true,
});

const cleanup = () => {
    window.history.replaceState({}, window.document.title, window.location.origin);
};

export const signInRedirect = (): Promise<void> => userManager.signinRedirect();

export const getUser = (): Promise<User | null> => userManager.getUser();

const login = async (): Promise<void> => {
    try {
        await userManager.signinRedirectCallback();
        await userManager.clearStaleState();
        return Promise.resolve();
    } catch (e) {
        return Promise.reject();
    } finally {
        cleanup();
    }
};

const logout = (): Promise<void> => userManager.removeUser();

const checkAuth = (): Promise<void> =>
    userManager
        .getUser()
        .then((user) => (user && !user.expired ? Promise.resolve() : Promise.reject({ message: false })));

const checkError = ({ status }: { status: number }): Promise<void> => {
    if (localStorage.getItem('login') === 'true') return Promise.resolve();
    switch (status) {
        case 401:
            return userManager.signinRedirect();
        case 403:
            return Promise.reject();
    }
    return Promise.resolve();
};

const getPermissions = (): Promise<void> => Promise.resolve();

const AuthProvider: RaAuthProvider = {
    login,
    logout,
    checkAuth,
    checkError,
    getPermissions,
};

export default AuthProvider;
